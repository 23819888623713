.playlistDesc {
    position: relative;
    font-size: 15px;
    color: #e2e2e2;
    padding: 2px 5px;

    max-height: 40px;
    /* line-height: 40px; */
    
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;

    max-width: 80%;
    margin: auto;

    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.artistName {
    position: relative;
    font-size: 23px;
    color: #e6e6e6;
    height: 30px;

    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.albumImgBox {}

