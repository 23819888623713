.box{
    font-size: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

a {
    font-size: 16px;
    color: #456dfd;
    text-decoration: none;
    transition: font-size 0.5s;
    padding: 5px 0;
}

a:hover {
    font-size: 18px
}