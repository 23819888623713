.artistAlbumNumber {
    position: relative;
    font-size: 15px;
    color: #e2e2e2;
    padding: 2px 5px;
    margin-top: 5px;
    min-height: 10px;
    max-width: 80%;

    

    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
}

.artistName {
    position: relative;
    font-size: 23px;
    color: #e6e6e6;
    max-height: 30px;
    
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.albumImgBox {}

