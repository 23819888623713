* {
    margin: 0;
    padding: 0;
    text-decoration: none;

    list-style-type:none;
    text-align: center;
    
    font-family: "Microsoft YaHei", 微软雅黑, "MicrosoftJhengHei", 华文细黑, MingLiu;
}
.bigBox {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    
}

.artist {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    width: 100%;
    height: auto;
    background-color: rgba(255, 255, 255, 0.15);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.artistImg {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 20px;
}

.artistInfo,.playlistInfo {
    position: relative;
    margin-top: 10px;
    max-height: 85px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.List {
    display: grid;
    margin: 5px 0 0 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow-y: scroll;
}

.albumBox {
    display: flex;
    position: relative;
    height: 100px;
    line-height: 100px;
    padding: 15px;
    max-width: 300px;
    margin: auto;
}

.albumImg {
    border-radius: 10px;
    margin-left: 20px;
}

.albumName {
    position: relative;
    width: 300px;
    font-size: 15px;
    padding: 0 10px;
    color: #eeeeeedc;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

}

.bgImg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    object-fit: fill;
    filter: blur(10px);
}

@media screen and (max-width: 1500px) {
    .List {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1200px) {
    .List {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 700px) {
    .List {
        grid-template-columns: 1fr;
    }
}


/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
.albumList::-webkit-scrollbar {
    /* display: none; */
}

/* 美化滚动条 */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    width: 6px;
    background: rgba(#101f1c, 0.1);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.5);
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    transition: background-color 0.3s;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, 0.3);
}
